import Typography from '@/components/core/Typography'
import StarIcon from '@/components/icons/StarIcon'
import { DivProps } from '@/types/common'
import clsx from 'clsx'
import styles from './UserPoints.module.scss'

type UserPointsProps = {
  points?: number
} & DivProps

const UserPoints = ({ points = 0, ...props }: UserPointsProps) => (
  <div {...props} className={clsx(props.className, styles.user_points)}>
    <StarIcon />
    <Typography variant="h7" size={'xsmall'}>
      <b>{points} points</b>
    </Typography>
  </div>
)

export default UserPoints
