import Link from '@/components/core/Link'
import {
  AMBASSADORS_URL,
  BENCHMARK_SURVEYS_URL,
  ONE_MINUTE_INSIGHTS_URL,
} from '@/components/organisms/NavHeader/constants'
import DrawerSubMenu from '../../DrawerSubMenu'
import { Sections } from '../../constants'
import styles from '../../DrawerSubMenu.module.scss'

const Resources = () => (
  <DrawerSubMenu label="Resources" parent={Sections.main}>
    <ul role="menu" className={styles.submenu_list}>
      <li role="menuitem">
        <Link href={BENCHMARK_SURVEYS_URL}>Benchmark Surveys</Link>
      </li>
      <li role="menuitem">
        <Link href={ONE_MINUTE_INSIGHTS_URL}>One-Minute Insights</Link>
      </li>
      <li role="menuitem">
        <Link href={AMBASSADORS_URL} target="_blank">
          Ambassador Program
        </Link>
      </li>
    </ul>
  </DrawerSubMenu>
)
export default Resources
