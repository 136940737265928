'use client'

import { useRedirectToAuthUrl } from '@/components/hooks/useRedirectToAuthUrl'
import { UNAUTHORIZED } from '@/services/auth/constants'
import { getBrowserCookies } from '@/util/browser'
import { ReactNode, useEffect, useMemo } from 'react'
import AppContext from './AppContext'

type AppProviderType = {
  children: ReactNode
  initialApp: {
    isBot: boolean
  }
}

const AppProvider = ({ children, initialApp }: AppProviderType) => {
  const isBot = initialApp.isBot

  const contextValue = useMemo(
    () => ({
      isBot,
    }),
    []
  )

  const { unauthorized } = useRedirectToAuthUrl()

  useEffect(() => {
    const redirect = getBrowserCookies()[UNAUTHORIZED]
    if (redirect === 'true') {
      unauthorized()
    }
  }, [])

  return <AppContext.Provider value={contextValue}>{children}</AppContext.Provider>
}

export default AppProvider
