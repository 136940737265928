'use client'
import Link from '@/components/core/Link'
import CloseIcon from '@/components/icons/CloseIcon'
import LogoIcon from '@/components/icons/LogoIcon'
import Footer from '@/components/organisms/Footer'
import clsx from 'clsx'
import React, { ComponentProps, useContext } from 'react'
import styles from './Drawer.module.scss'
import { DrawerContext, DrawerProvider } from './DrawerContext'
import Connect from './components/Connect'
import Events from './components/Events'
import Main from './components/Main'
import Resources from './components/Resources'
import { Sections } from './constants'

const MENU_LEVELS = {
  [Sections.main]: Main,
  [Sections.resources]: Resources,
  [Sections.connect]: Connect,
  [Sections.events]: Events,
}
type DrawerProps = {
  closeHandler: () => void
} & React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>

const Drawer = ({ closeHandler, className, ...props }: DrawerProps) => {
  const { level } = useContext(DrawerContext)

  const CurrentMenu = MENU_LEVELS[level]

  return (
    <div {...props} className={clsx(className, styles.wrapper)} data-testid="drawer">
      <div className={styles.drawer_overlay} onClick={closeHandler} />
      <div className={styles.drawer_content}>
        <header className={styles.header}>
          <Link prefetch={false} href="/home" aria-label="Gartner Peer Community Home">
            <LogoIcon />
          </Link>
          <button className={styles.close_button} onClick={closeHandler} aria-label="Close drawer">
            <CloseIcon />
          </button>
        </header>
        <div className={styles.menu}>
          <CurrentMenu />
        </div>
        <Footer className={styles.footer} />
      </div>
    </div>
  )
}

const DrawerContainer = (props: ComponentProps<typeof Drawer>) => (
  <DrawerProvider>
    <Drawer {...props} />
  </DrawerProvider>
)

export default DrawerContainer
