const LinkedInIcon = ({ size = 32 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    fill="none"
    viewBox="0 0 32 33"
  >
    <path
      fill="currentColor"
      d="M16 32.3896c-8.8366 0-16-7.1634-16-16 0-8.8365 7.1634-16 16-16s16 7.1635 16 16c0 8.8366-7.1634 16-16 16Zm0-1.4885c8.0143 0 14.5114-6.4972 14.5114-14.5115 0-8.0142-6.4971-14.5114-14.5114-14.5114S1.4886 8.3754 1.4886 16.3896c0 8.0143 6.4971 14.5115 14.5114 14.5115Zm-7.2829-7.9772h3.0075v-9.6234H8.7171v9.6234Zm1.4269-10.8285h-.0217c-1.088 0-1.7937-.7355-1.7937-1.6669 0-.9508.7263-1.6714 1.8365-1.6714 1.1086 0 1.7915.7183 1.8126 1.6685.0006.9315-.7034 1.6698-1.8337 1.6698Zm13.6103 10.8285h-3.4097v-4.98c0-1.304-.532-2.1931-1.7057-2.1931-.8966 0-1.3955.6-1.6275 1.1788-.0868.2069-.0737.4955-.0737.7869v5.208H13.56s.044-8.8223 0-9.624h3.3777v1.5097c.1994-.6606 1.2783-1.604 3.0012-1.604 2.1365 0 3.8154 1.3852 3.8154 4.3663v5.3514Z"
    />
  </svg>
)

export default LinkedInIcon
