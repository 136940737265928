import SearchIcon from '@/components/icons/SearchIcon'
import styles from './SearchBox.module.scss'

const SearchBox = () => (
  <div className={styles.search}>
    <form action="/peer-community/search" aria-label="Search form" className={styles.search_form}>
      <div className={styles.search_control}>
        <input
          className={styles.search_input}
          name="text"
          type="text"
          placeholder="Search for insights, advice, and more"
          aria-label="Search query"
          required
          autoFocus
        />
        <button type="submit" className={styles.submit_btn} aria-label="Go to search results">
          <SearchIcon size={20} />
        </button>
      </div>
    </form>
  </div>
)

export default SearchBox
