import Typography from '@/components/core/Typography'

const currentYear = new Date().getFullYear()

const Copyright = () => (
  <Typography as="p" variant="disclaimer">
    © {currentYear} Gartner, Inc. and/or its affiliates. All rights reserved.
  </Typography>
)

export default Copyright
