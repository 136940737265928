const TwitterIcon = ({ size = 32 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    fill="none"
    viewBox="0 0 32 33"
  >
    <path
      fill="currentColor"
      d="M16 32.3896c-8.8369 0-16-7.1637-16-16 0-8.8362 7.1631-16 16-16s16 7.1632 16 16c0 8.8369-7.1637 16-16 16Zm0-1.4884c8.0146 0 14.5116-6.497 14.5116-14.5116C30.5116 8.3751 24.014 1.8781 16 1.8781S1.4879 8.375 1.4879 16.3896c0 8.0146 6.4975 14.5116 14.5121 14.5116Zm6.8046-18.146a5.5865 5.5865 0 0 1-1.6005.4391c.5758-.3448 1.0178-.8914 1.2254-1.5422a5.6005 5.6005 0 0 1-1.7709.6759 2.7832 2.7832 0 0 0-2.0351-.8806c-1.5382 0-2.787 1.2489-2.787 2.7876 0 .2196.024.4312.072.6348-2.3176-.1167-4.3704-1.2249-5.7467-2.9134a2.7872 2.7872 0 0 0-.3774 1.4038c0 .9658.4917 1.8201 1.2408 2.3204a2.7942 2.7942 0 0 1-1.2637-.3482v.0343c0 1.3512.9606 2.4782 2.2369 2.7333-.2344.0657-.4797.0983-.7353.0983-.1796 0-.354-.0166-.5238-.0492.3539 1.1065 1.3832 1.9133 2.604 1.9356-.9538.7474-2.1569 1.1928-3.4629 1.1928a5.776 5.776 0 0 1-.6644-.0377c1.2339.7902 2.6989 1.2517 4.2732 1.2517 5.1291 0 7.9322-4.2469 7.9322-7.9322 0-.1213-.0023-.2419-.0074-.3609a5.6672 5.6672 0 0 0 1.3906-1.4432Z"
    />
  </svg>
)

export default TwitterIcon
