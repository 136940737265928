type ArrowDownFilledIconProps = {
  size?: number
}

const ArrowDownFilledIcon = ({ size = 24 }: ArrowDownFilledIconProps) => (
  <svg width={size} height="100%" viewBox="0 0 12 6" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 0L6 6L12 0H0Z" fill="currentColor" />
  </svg>
)

export default ArrowDownFilledIcon
