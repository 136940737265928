import Link from '@/components/core/Link'
import Typography from '@/components/core/Typography'

import styles from './LogoutFailed.module.scss'

const LogoutFailed = () => (
  <Typography as="span" variant="paragraph">
    Logout failed. Please try again! Alternatively you can try to logout from{' '}
    <Link href={`${process.env.NEXT_PUBLIC_BASE_URL}/peer-community`} className={styles.link}>
      here
    </Link>
  </Typography>
)

export default LogoutFailed
