'use client'
import Button from '@/components/core/Button'
import ArrowLeftIcon from '@/components/icons/ArrowLeftIcon'
import SearchIcon from '@/components/icons/SearchIcon'
import clsx from 'clsx'
import { useState } from 'react'
import styles from './Search.module.scss'
import SearchBox from './sub/SearchBox'

type SearchProps = {}
const Search = ({}: SearchProps) => {
  const [searchVisible, setSearchVisible] = useState(false)

  const toggleSearchVisible = () => setSearchVisible((visible) => !visible)

  return (
    <div className={clsx(styles.search, styles[`search_${searchVisible}`])}>
      <Button variant="ghost" onClick={toggleSearchVisible} className={styles.search_icon}>
        {searchVisible ? <ArrowLeftIcon /> : <SearchIcon size={20} />}
      </Button>
      <SearchBox focused={searchVisible} />
    </div>
  )
}

export default Search
