'use client'

import useAnalyticsEventListener from '@/components/hooks/useAnalyticsEventListener'
import events from '@/services/events'
import { PublicProfileType } from '@/types/api'
import { usePathname, useSearchParams } from 'next/navigation'
import Script from 'next/script'
import { useEffect } from 'react'

const initializeAnalytics = () => {
  window.dataLayer = window.dataLayer || []
  window.gtag = function () {
    window.dataLayer.push(arguments)
  }
}

const routeMap = [
  { path: '/poll', name: 'Poll detailed view' },
  { path: '/post', name: 'Long-form detailed view' },
  { path: '/oneminuteinsights/', name: 'One-Minute Insights DetailView Page' },
  { path: '/oneminuteinsights', name: 'One-Minute Insights' },
  { path: '/home', name: 'Home Page' },
  { path: '/ambassadors', name: 'Ambassadors Page' },
  { path: '/discussions/', name: 'Discussions DetailView Page' },
  { path: '/discussions', name: 'Discussions Page' },
  { path: '/profile', name: 'Profile DetailView Page' },
  { path: '/business-community', name: 'Business Community Landing' },
  { path: '/technology-community', name: 'Technology Community Landing' },
]

type AnalyticsType = {
  profile: PublicProfileType
}

const Analytics = ({ profile }: AnalyticsType) => {
  const pathname = usePathname()
  const searchParams = useSearchParams()
  const { isLoaded } = useAnalyticsEventListener()

  useEffect(() => {
    initializeAnalytics()

    window.gtag('js', new Date())
    window.gtag('config', process.env.NEXT_PUBLIC_GA_TRACKING_ID, { send_page_view: false })
  }, [])

  const isLoggedIn = !profile.isAnonymous
  const getPageName = (pathname: string) => {
    const route = routeMap.find(({ path }) => pathname.includes(path))
    return route?.name ?? 'UNSET'
  }

  const getPageType = (pathname: string) => (pathname.includes('/home') ? 'Home' : 'Content')

  useEffect(() => {
    window.gtag('set', 'user_properties', {
      site_code: 'peer_community',
      user_is_ambassador: profile.isAmbassador,
      user_logged_in: isLoggedIn,
      user_is_client: profile.isCustomer,
      user_title: isLoggedIn ? profile.title : 'UNSET',
      user_company_name: isLoggedIn ? profile.companyName : 'UNSET',
      user_company_size: isLoggedIn ? profile.companySize : 'UNSET',
      user_industry: isLoggedIn ? profile.industry : 'UNSET',
      user_id_gpc: profile.id || 'UNSET',
      user_creation_date: profile.firstSeen || 'UNSET',
      user_level: profile.level || 'UNSET',
      user_function: profile.primaryFunction || 'UNSET',
    })

    if (isLoaded) return

    window.gtag(
      'get',
      process.env.NEXT_PUBLIC_GA_TRACKING_ID,
      'client_id',
      async (clientId: string) => {
        await events.postCustomEvent({
          event: 'page_view',
          page: window.location.pathname,
          properties: {
            title: document.title,
            is_mobile: false,
            clientId,
            page_name: getPageName(pathname),
            page_type: getPageType(pathname),
          },
        })
      }
    )
  }, [pathname, searchParams])

  return (
    <>
      <Script
        strategy="lazyOnload"
        src="https://cdn.debugbear.com/T3AkDozOLODX.js"
        id="debugbear"
      />
      <Script
        strategy="lazyOnload"
        src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GA_TRACKING_ID}`}
      />

      {process.env.NEXT_PUBLIC_FS_ORG_ID && (
        <Script
          strategy="lazyOnload"
          id="fullstory"
          dangerouslySetInnerHTML={{
            __html: `window['_fs_host'] = 'fullstory.com';
            window['_fs_script'] = 'edge.fullstory.com/s/fs.js';
            window['_fs_org'] = '${process.env.NEXT_PUBLIC_FS_ORG_ID}';
            window['_fs_namespace'] = 'FS';
            (function(m,n,e,t,l,o,g,y){
                if (e in m) {if(m.console && m.console.log) { m.console.log('FullStory namespace conflict. Please set window["_fs_namespace"].');} return;}
                g=m[e]=function(a,b,s){g.q?g.q.push([a,b,s]):g._api(a,b,s);};g.q=[];
                o=n.createElement(t);o.async=1;o.crossOrigin='anonymous';o.src='https://'+_fs_script;
                y=n.getElementsByTagName(t)[0];y.parentNode.insertBefore(o,y);
                g.identify=function(i,v,s){g(l,{uid:i},s);if(v)g(l,v,s)};g.setUserVars=function(v,s){g(l,v,s)};g.event=function(i,v,s){g('event',{n:i,p:v},s)};
                g.anonymize=function(){g.identify(!!0)};
                g.shutdown=function(){g("rec",!1)};g.restart=function(){g("rec",!0)};
                g.log = function(a,b){g("log",[a,b])};
                g.consent=function(a){g("consent",!arguments.length||a)};
                g.identifyAccount=function(i,v){o='account';v=v||{};v.acctId=i;g(o,v)};
                g.clearUserCookie=function(){};
                g.setVars=function(n, p){g('setVars',[n,p]);};
                g._w={};y='XMLHttpRequest';g._w[y]=m[y];y='fetch';g._w[y]=m[y];
                if(m[y])m[y]=function(){return g._w[y].apply(this,arguments)};
                g._v="1.3.0";
            })(window,document,window['_fs_namespace'],'script','user');`,
          }}
        />
      )}
      <Script
        strategy="lazyOnload"
        id="fb-pixel"
        dangerouslySetInnerHTML={{
          __html: `!function(f,b,e,v,n,t,s)
{if(f.fbq)return;n=f.fbq=function(){n.callMethod?
n.callMethod.apply(n,arguments):n.queue.push(arguments)};
if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
n.queue=[];t=b.createElement(e);t.async=!0;
t.src=v;s=b.getElementsByTagName(e)[0];
s.parentNode.insertBefore(t,s)}(window, document,'script',
'https://connect.facebook.net/en_US/fbevents.js');
fbq('init', '1960447244259117');
fbq('track', 'PageView');`,
        }}
      />
      <Script
        strategy="lazyOnload"
        id="lnkdin-pixel"
        dangerouslySetInnerHTML={{
          __html: `_linkedin_partner_id = "4725609";
window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];
window._linkedin_data_partner_ids.push(_linkedin_partner_id);
(function(l) {
if (!l){window.lintrk = function(a,b){window.lintrk.q.push([a,b])};
window.lintrk.q=[]}
var s = document.getElementsByTagName("script")[0];
var b = document.createElement("script");
b.type = "text/javascript";b.async = true;
b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js";
s.parentNode.insertBefore(b, s);})(window.lintrk);`,
        }}
      />

      <noscript>
        {/* eslint-disable-next-line @next/next/no-img-element */}
        <img
          height="1"
          width="1"
          style={{ display: 'none' }}
          alt=""
          src="https://px.ads.linkedin.com/collect/?pid=6535508&fmt=gif"
        />
        {/* eslint-disable-next-line @next/next/no-img-element */}
        <img
          height="1"
          width="1"
          style={{ display: 'none' }}
          alt=""
          src="https://www.facebook.com/tr?id=1960447244259117&ev=PageView&noscript=1"
        />
      </noscript>
    </>
  )
}

export default Analytics
