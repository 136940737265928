'use client'

import { useRedirectToAuthUrl } from '@/components/hooks/useRedirectToAuthUrl'

const AuthRedirects = () => {
  const { redirect: redirectIfNeeded } = useRedirectToAuthUrl()
  redirectIfNeeded()
  return <></>
}

export default AuthRedirects
