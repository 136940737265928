import SearchIcon from '@/components/icons/SearchIcon'
import { FormProps } from '@/types/common'
import clsx from 'clsx'
import styles from './SearchBox.module.scss'

type SearchBoxProps = {
  focused?: boolean
} & FormProps
const SearchBox = ({ focused = false, ...props }: SearchBoxProps) => (
  <form
    {...props}
    action="/peer-community/search"
    className={clsx(styles.search, styles[`search_${focused}`])}
  >
    <SearchIcon size={20} />

    <input
      name="text"
      type="text"
      placeholder="Search for insights, advice, and more"
      aria-label="Search query"
      required
      autoFocus
    />
  </form>
)
export default SearchBox
