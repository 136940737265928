'use client'
import SignupButton from '@/components/core/SignupButton'
import { EventContext } from '@/components/providers/eventContext'

// Temporary component to encapsulte the Event Context Provider inside the NavHeader CTA button,
// and be able to convert the header a server component. Context should be inside SignUpButton, but we
// created this temporary component in order to avoid changing all SignupButton usages for now.
// TODO: Move Event Context Provider inside SignupButton and fix all usages
const CtaButton = () => (
  <EventContext.Provider value={{ component: 'header' }}>
    <SignupButton>Join / Sign In</SignupButton>
  </EventContext.Provider>
)

export default CtaButton
