import Typography from '@/components/core/Typography'
import ImageWithFallback from '@/components/organisms/NavHeaderLoggedIn/sub/ImageWithFallback'
import { PublicProfileType } from '@/types/api'
import { DivProps, Size } from '@/types/common'
import clsx from 'clsx'
import styles from './Connection.module.scss'
type ConnectionParams = DivProps & { profile: PublicProfileType }

const Connection = ({ profile, className, ...props }: ConnectionParams) => (
  <div className={clsx(styles.connection, className)} {...props}>
    <ImageWithFallback
      showBadge
      size={Size.medium}
      isAmbassador={profile.isAmbassador}
      isVerified={profile.isVerified}
      profileImage={profile.pic}
      className={styles.connection_pic}
    />
    <div className={styles.connection_info}>
      <Typography variant="h5" className={styles.connection_name}>
        {profile.displayname}
      </Typography>
      <Typography variant="h7" className={styles.connection_title}>
        {profile.title}
      </Typography>
      <Typography variant="h7" className={styles.connection_title}>
        {profile.email}
      </Typography>
    </div>
  </div>
)
export default Connection
