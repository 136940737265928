type HamburgerIconProps = {
  size?: number
}

const HamburgerIcon = ({ size = 24 }: HamburgerIconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    width={size}
    height={size}
    viewBox={`0 0 ${size} ${size}`}
  >
    <title>hamburger icon</title>
    <path fill="none" d="M0 0h24v24H0V0z" />
    <path d="M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z" />
  </svg>
)

export default HamburgerIcon
