import { request } from '@/services/base'

const postCustomEvent = (data = {}) =>
  request({
    path: `/api/v1/event`,
    options: {
      method: 'POST',
      body: JSON.stringify(data),
    },
  })

const EventClient = { postCustomEvent }
export default EventClient
