import Button from '@/components/core/Button'
import Spinner from '@/components/core/Spinner'
import Typography from '@/components/core/Typography'
import { Modal } from '@/components/organisms/Modal'
import { getAllConnections } from '@/services/directory/actions'
import { PublicProfileType } from '@/types/api'

import Link from '@/components/core/Link'
import { paginate } from '@/util/iterators'
import { pluralize } from '@/util/strings'
import clsx from 'clsx'
import { useEffect, useState } from 'react'
import styles from './ModalConnections.module.scss'
import Connection from './sub/Connection'

const ModalConnections = ({ onCloseHandler }: { onCloseHandler?: () => void }) => {
  const MAX_CONNECTIONS_PER_PAGE = 20

  const [currentPage, setCurrentPage] = useState(1)
  const [connectionCount, setConnectionCount] = useState(0)
  const [connections, setConnections] = useState<PublicProfileType[]>([])
  const [loading, setLoading] = useState<boolean>(true)

  useEffect(() => {
    const updateConns = async () => {
      setLoading(true)
      const { profiles, profileCount } = await getAllConnections({ page: currentPage })
      setConnections(profiles)
      setLoading(false)
      setConnectionCount(profileCount)
    }
    updateConns()
  }, [currentPage])

  const hasConnections = connectionCount > 0
  const totalPages = Math.ceil(connectionCount / MAX_CONNECTIONS_PER_PAGE)
  const pages = paginate(currentPage, totalPages, 10)

  return (
    <Modal onClose={onCloseHandler} className={clsx(styles.modal, styles.root)}>
      <Modal.Header onCloseHandler={onCloseHandler} className={styles.modal_header}>
        <Typography as="h2" variant="h2" className={styles.connections_title}>
          <span>Connections</span>
        </Typography>
      </Modal.Header>
      <Modal.Body className={styles.modal_body}>
        {loading && <Spinner className={styles.spinner} />}
        {!loading && !hasConnections && (
          <Typography as="h4" variant="h4">
            No connections yet!
          </Typography>
        )}
        {!loading && hasConnections && (
          <div className={styles.scroller}>
            <Typography as="h3" variant="h5" className={styles.connections_results}>
              {connectionCount} {pluralize(connectionCount, 'Connection')}
            </Typography>
            <ul className={styles.connection_list}>
              {connections.map((profile) => (
                <li key={profile.slug}>
                  <Link key={profile.slug} href={`/profile/${profile.slug}`}>
                    <Connection profile={profile} onClick={onCloseHandler} />
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer className={styles.modal_footer}>
        <div className={styles.connections_pages}>
          {pages?.map((page, index) => (
            <Button
              key={index}
              variant="ghost"
              onClick={() => page && setCurrentPage(page)}
              disabled={!page}
              className={page === currentPage && styles.current_page}
            >
              {page || '...'}
            </Button>
          ))}
        </div>
      </Modal.Footer>
    </Modal>
  )
}

export default ModalConnections
