import Typography from '@/components/core/Typography'
import useProfile from '@/components/providers/profile/useProfile'
import MenuItem from '../MenuItems/sub/MenuItem'
import styles from './PrivateCommunities.module.scss'

const PrivateCommunities = () => {
  const {
    profile: { groupsMembership },
  } = useProfile()

  if (!groupsMembership?.length) return <></>
  return (
    <div className={styles.container}>
      <Typography variant="disclaimer" size="small" className={styles.title}>
        <b>MY PRIVATE COMMUNITIES</b>
      </Typography>
      <div className={styles.menu_item}>
        {groupsMembership.map(({ name, slug }) => (
          <MenuItem key={slug} name={name} href={`/ch/${slug}`} className={styles.menu_item} />
        ))}
      </div>
    </div>
  )
}

export default PrivateCommunities
