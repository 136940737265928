'use client'

import Link from '@/components/core/Link'
import ArrowRightIcon from '@/components/icons/ArrowRightIcon'
import OpenExternalIcon from '@/components/icons/OpenExternalIcon'
import useProfile from '@/components/providers/profile/useProfile'
import { MouseEventHandler, useContext } from 'react'
import { DrawerContext } from '../../DrawerContext'
import { Sections } from '../../constants'

const ButtonItem = ({ label, onClick }: { label: string; onClick: MouseEventHandler }) => (
  <button onClick={onClick} aria-label={`Open ${label}`}>
    {label}
    <ArrowRightIcon />
  </button>
)

const Main = () => {
  const {
    profile: { isAnonymous, hasPeerFinderAccess, groupsMembership },
  } = useProfile()
  const { setLevel } = useContext(DrawerContext)

  const handleClick = (section: Sections) => () => {
    setLevel(section)
  }

  const shouldRenderConnect =
    hasPeerFinderAccess ||
    (groupsMembership?.length > 0 &&
      process.env['NEXT_PUBLIC_PRIVATE_COMMUNITIES_ENABLED'] === 'true')

  return (
    <ul role="menu">
      <li role="menuitem">
        <Link prefetch={false} href="/home">
          Home
        </Link>
      </li>
      {!isAnonymous && (
        <>
          <li role="menuitem">
            <ButtonItem label="Resources" onClick={handleClick(Sections.resources)} />
          </li>
          {shouldRenderConnect && (
            <li role="menuitem">
              <ButtonItem label="Connect" onClick={handleClick(Sections.connect)} />
            </li>
          )}
          <li role="menuitem">
            <ButtonItem label="Events" onClick={handleClick(Sections.events)} />
          </li>
        </>
      )}
      <li role="menuitem">
        <Link href="https://www.gartner.com/peer-insights/home">
          Gartner Peer Insights™
          <OpenExternalIcon size={15} />
        </Link>
      </li>
      <li role="menuitem">
        <Link href="https://www.gartner.com/">
          Gartner.com
          <OpenExternalIcon size={15} />
        </Link>
      </li>
    </ul>
  )
}
export default Main
