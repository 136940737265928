//RESOURCES
export const BENCHMARK_SURVEYS_URL = '/reports/industry/unanswered'
export const ONE_MINUTE_INSIGHTS_URL = '/reports/industry/oneminuteinsights'
export const AMBASSADORS_URL = '/ambassadors'

// CONNECT
export const PEER_FINDER_URL = '/peer-finder'

// EVENTS
export const EVANTA_URL = 'https://www.evanta.com/'
export const DESTINATION_CONFERENCES_URL = 'https://www.gartner.com/en/conferences/calendar'
export const RESEARCH_BOARD_URL = 'https://www.gartner.com/en/gartner-research-board'
