'use client'
import Button from '@/components/core/Button'
import SearchIcon from '@/components/icons/SearchIcon'
import { useState } from 'react'

import clsx from 'clsx'
import styles from './Search.module.scss'
import SearchBox from './sub/SearchBox'

const Search = () => {
  const [searchVisible, setSearchVisible] = useState(false)

  const toggleSearchVisible = () => setSearchVisible((visible) => !visible)

  return (
    <>
      <Button
        onClick={toggleSearchVisible}
        className={clsx(styles.search, styles.toggle_search)}
        aria-label="Show search box"
        variant="ghost"
      >
        <SearchIcon size={20} />
      </Button>
      {searchVisible && <SearchBox />}
    </>
  )
}

export default Search
