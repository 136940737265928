'use client'
import Button from '@/components/core/Button'
import CreateIcon from '@/components/icons/CreateIcon'
import { useModal } from '@/components/organisms/Modal'
import dynamic from 'next/dynamic'
import UserActions from '../UserActions'
import styles from './CreateButton.module.scss'

type CreateButtonProps = { accessFormRequired: boolean }

const ModalPersonalizeExperience = dynamic(
  () => import('@/components/organisms/ModalFullAccessForm')
)

const CreateButton = ({ accessFormRequired }: CreateButtonProps) => {
  const [showModal, hideModal] = useModal(
    () => <ModalPersonalizeExperience isClosable onCloseHandler={hideModal} />,
    []
  )

  return (
    <>
      {!accessFormRequired ? (
        <UserActions className={styles.user_actions} />
      ) : (
        <Button className={styles.user_actions_button} variant="ghost" onClick={showModal}>
          <CreateIcon size={32} />
        </Button>
      )}
    </>
  )
}

export default CreateButton
