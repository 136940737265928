import Dropdown from '@/components/core/Dropdown'
import Link from '@/components/core/Link'
import Typography from '@/components/core/Typography'
import clsx from 'clsx'
import styles from './MenuItem.module.scss'

type MenuItemProps = {
  name: string
  href: string
  target?: string
  className?: string
}
const MenuItem = ({ name, href, target, className }: MenuItemProps) => (
  <Dropdown.Item className={clsx(styles.menu_item, className)}>
    <Link href={href} target={target}>
      <Typography variant="h6" as="span">
        <b>{name}</b>
      </Typography>
    </Link>
  </Dropdown.Item>
)

export default MenuItem
