import Link from '@/components/core/Link'
import {
  DESTINATION_CONFERENCES_URL,
  EVANTA_URL,
  RESEARCH_BOARD_URL,
} from '@/components/organisms/NavHeader/constants'
import DrawerSubMenu from '../../DrawerSubMenu'
import { Sections } from '../../constants'
import styles from '../../DrawerSubMenu.module.scss'

const Events = () => (
  <DrawerSubMenu label="Events" parent={Sections.main}>
    <ul role="menu" className={styles.submenu_list}>
      <li role="menuitem">
        <Link href={EVANTA_URL} target="_blank">
          Evanta
        </Link>
      </li>
      <li role="menuitem">
        <Link href={DESTINATION_CONFERENCES_URL} target="_blank">
          Destination Conferences
        </Link>
      </li>
      <li role="menuitem">
        <Link href={RESEARCH_BOARD_URL} target="_blank">
          Research Board
        </Link>
      </li>
    </ul>
  </DrawerSubMenu>
)
export default Events
