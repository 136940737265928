'use client'
import Button from '@/components/core/Button'
import { useClickOutside } from '@/components/hooks/useClickOutside'
import { DivProps } from '@/types/common'
import clsx from 'clsx'
import {
  Children,
  cloneElement,
  isValidElement,
  ReactElement,
  ReactNode,
  useRef,
  useState,
} from 'react'
import styles from './Dropdown.module.scss'

type DropdownProps = {
  trigger: ReactNode
  openRight?: boolean
} & DivProps

type DropdownItemProps = {
  close?: () => void
} & DivProps

const DropdownItem = ({ className, close, ...props }: DropdownItemProps) => (
  <div
    className={clsx(className, styles.dropdown_item)}
    onClick={close && (() => close())}
    role="button"
    {...props}
  />
)

const Dropdown = ({ children, className, trigger, openRight = false, ...props }: DropdownProps) => {
  const [isVisible, setIsVisible] = useState(false)
  const dropdownRef = useRef<HTMLDivElement | null>(null)
  const openDropdown = () => setIsVisible(true)
  const close = () => setIsVisible(false)

  if (typeof window !== 'undefined') {
    window.addEventListener('pageshow', (event) => event.persisted && close())
  }

  useClickOutside({
    ref: dropdownRef,
    callback: close,
  })

  const dropdownItems = Children.map(children, (child) => {
    if (isValidElement(child)) {
      return cloneElement(child as ReactElement<DropdownItemProps>, { close })
    }
    return child
  })

  return (
    <div {...props} className={clsx(className, styles.dropdown)} ref={dropdownRef}>
      <Button className={styles.button} variant="ghost" onClick={openDropdown}>
        {trigger}
      </Button>
      {isVisible && (
        <div className={clsx(styles.dropdown_menu, openRight && styles.menu_right)}>
          {dropdownItems}
        </div>
      )}
    </div>
  )
}

Dropdown.Item = DropdownItem

export default Dropdown
