type OpenExternalIconProps = {
  size?: number
}

const OpenExternalIcon = ({ size = 24 }: OpenExternalIconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    fill="none"
    viewBox="0 0 18 18"
  >
    <path
      fill="currentColor"
      d="M16 16H2V2h7V0H2C.89 0 0 .9 0 2v14c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V9h-2v7ZM11 0v2h3.59l-9.83 9.83 1.41 1.41L16 3.41V7h2V0h-7Z"
    />
  </svg>
)

export default OpenExternalIcon
