import Typography from '@/components/core/Typography'
import ArrowLeftIcon from '@/components/icons/ArrowLeftIcon'
import { PropsWithChildren, useContext } from 'react'
import { Sections } from './constants'

import styles from './DrawerSubMenu.module.scss'
import { DrawerContext } from './DrawerContext'

const DrawerSubMenu = ({
  label,
  parent,
  children,
}: PropsWithChildren<{ label: string; parent: Sections }>) => {
  const { setLevel } = useContext(DrawerContext)
  return (
    <div className={styles.menu}>
      {parent !== undefined && (
        <button
          onClick={() => setLevel(parent)}
          className={styles.back_button}
          aria-label={`Close ${label}`}
        >
          <ArrowLeftIcon size={40} />
          <Typography variant="h5">{label}</Typography>
        </button>
      )}
      {children}
    </div>
  )
}

export default DrawerSubMenu
