const SearchIcon = ({ size = 24 }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 21 21">
    <title>search icon</title>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.6671 13.3334H13.7337L13.3837 12.9834C14.5504 11.7 15.2504 9.95003 15.2504 8.08336C15.2504 3.88336 11.8671 0.500031 7.66707 0.500031C3.46707 0.500031 0.0837402 3.88336 0.0837402 8.08336C0.0837402 12.2834 3.46707 15.6667 7.66707 15.6667C9.53374 15.6667 11.2837 14.9667 12.5671 13.8L12.9171 14.15V15.0834L18.7504 20.9167L20.5004 19.1667L14.6671 13.3334ZM7.66707 13.3334C4.75041 13.3334 2.41707 11 2.41707 8.08336C2.41707 5.1667 4.75041 2.83336 7.66707 2.83336C10.5837 2.83336 12.9171 5.1667 12.9171 8.08336C12.9171 11 10.5837 13.3334 7.66707 13.3334Z"
      fill="currentColor"
    />
  </svg>
)

export default SearchIcon
