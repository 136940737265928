import { PropsWithChildren, createContext, useMemo, useState } from 'react'
import { Sections } from './constants'

const DrawerContext = createContext<{
  level: Sections
  setLevel: (section: Sections) => void
}>({
  level: Sections.main,
  setLevel: () => {},
})

const DrawerProvider = ({ children }: PropsWithChildren) => {
  const [level, setLevel] = useState(Sections.main)
  const value = useMemo(
    () => ({
      level,
      setLevel,
    }),
    [level]
  )

  return <DrawerContext.Provider value={value}>{children}</DrawerContext.Provider>
}

export { DrawerContext, DrawerProvider }
